import { FC, useEffect, useState } from "react";
import { BigCard, Features, GamesList, Layout } from "@/components";
import styles from "./styles.module.scss"
import { FetchService } from "@/utils";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
const CategoriesPage: FC = () => {

  const [games, setGames] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState<any>({})

  const { category } = useParams()

  const getCategory = async () => {
    try {
      const data = await FetchService(`category/${category}`)
      setSelectedCategory(data?.result)
    } catch (error) {
      console.log(error)
    }
  }

  const getGames = async () => {

    try {
      const data = await FetchService('game/paginate', 'POST', { page: 1, limit: 10, query: { feature: true, category: [selectedCategory?.name] } })
      const featureSlider = data?.result?.map((item: any, index: number) => (
        <BigCard key={index} game={item} />
      ))
      setGames(featureSlider)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])


  useEffect(() => {
    if (selectedCategory?.name) getGames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  return (
    <Layout>
      <Features data={games} title={`Juegos de ${selectedCategory?.name} Destacados`} />
      <GamesList category={selectedCategory?.name} />
    {/*   <Box className={styles._adContainer}>
        <Box sx={{ border: '1px solid #DADADA', color: '#DADADA', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '160px' }}>
          AD
        </Box>
      </Box> */}
    </Layout>
  )
}

export default CategoriesPage