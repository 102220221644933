import { Box } from "@mui/material";
import { FC } from "react";
import { useSwiper } from "swiper/react";

const Arrow: FC<any> = ({ direction }) => {

  const swiper = useSwiper();

  const onClick = () => {
    if (direction === 'next') {
      swiper.slideNext();
    } else {
      swiper.slidePrev();
    }
  }
  return (
    <Box sx={{ width: '32px', height: '32px', cursor: 'pointer', transform: direction === 'prev' ? 'rotate(180deg)' : 'rotate(0deg)' }} onClick={onClick}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.9066 17.4199L12.2466 25.7199C10.8466 26.9199 8.66661 25.9199 8.66661 24.0799L8.66661 7.49989C8.66661 5.63989 10.8466 4.65989 12.2466 5.85989L21.9066 14.1399C22.9066 14.9999 22.9066 16.5599 21.9066 17.4199Z" fill="#E9F7CF" />
      </svg>
    </Box>
  )
}

export default Arrow