import { FetchService } from "@/utils";
import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import Card from "../Card";
import styles from "./styles.module.scss"
const GamesList: FC<any> = ({ category = [], fullWidth = false }) => {

  const [games, setGames] = useState<any[]>([])
  
  const getGames = async () => {
    try {
      const data = await FetchService('game/paginate', 'POST', { page: 1, limit: 30, query: { category: category?.length ? [category] : null } })
      setGames(data?.result)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getGames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.length])

  return (
    <Box className={styles._container} sx={{ width: '100%', maxWidth: '881px', margin: '0 auto', padding: fullWidth ? '2rem 0rem' : '2rem 3rem', display: 'grid', gridTemplateColumns: fullWidth ? '1fr' : 'repeat(auto-fill, 7.76042rem)', gap: '0.5rem' }}>
      {games?.map((item: any, index: number) => (
        <Card key={`${item?.name}-${index}`} game={item} />
      ))}
    </Box>
  )
}

export default GamesList