import React, { FC } from "react";
import styles from "./styles.module.scss"
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const BigCard: FC<any> = ({ game }) => {
  return (
    <Link to={`/games/${game?.url}`} style={{ maxWidth: '43.5625rem', width: '100%', margin: '0 auto' }} >
      <Box width={'100%'} maxWidth={'43.5625rem'} margin={'0 auto'} sx={{ cursor: 'pointer' }}>
        <Box className={styles._imageContainer}>
          <img src={game?.image} alt="banner" width={'100%'} height={'100%'} className={styles._image} />
        </Box>
        <Box className={styles._content}>
          <h3 className={styles._title}>
            {game?.name}
          </h3>
        </Box>
      </Box>
    </Link>
  )
}

export default BigCard