import Phaser from "phaser";
import back from '../assets/city.png';
import back2 from '../assets/city-2.png';
import bunny from '../assets/TestCharacter.png';
import floor from '../assets/floor.png';
import doc from '../assets/doc.png';
import soldier from '../assets/soldier.png';
import heart from '../assets/heart.png';
class GameScene extends Phaser.Scene {

  keyboard: Phaser.Types.Input.Keyboard.CursorKeys | undefined
  player: any
  docs: Phaser.Physics.Arcade.Group | undefined
  soldiers: Phaser.Physics.Arcade.Group | undefined
  background1: any;
  background2: any;
  score = 0;
  scoreText: Phaser.GameObjects.Text | undefined
  lives = 3;
  heart: Phaser.GameObjects.Group | undefined
  colliderPlayer: any
  gameOver = true;
  constructor() {
    super('game');
  }



  preload() {

    this.load.spritesheet('bunny', bunny, { frameWidth: 65, frameHeight: 48, margin: -8, spacing: 0 });
    this.load.spritesheet('soldier', soldier, { frameWidth: 256, frameHeight: 256, margin: -34, spacing: 0 });
    this.load.image('doc', doc);
    this.load.image('back', back);
    this.load.image('floor', floor);
    this.load.image('back2', back2);
    this.load.image('heart', heart)
  }

  addFullScreenButton() {
    let fullscreenButton = this.add.text(700, 50, 'Full Screen', {
      fontSize: '24px',
      color: '#ffffff',
      backgroundColor: '#000000',
      padding: { x: 10, y: 5 }
    }).setInteractive();

    // Toggle full screen when the button is clicked
    fullscreenButton.on('pointerdown', () => {
      if (this.scale.isFullscreen) {
        this.scale.stopFullscreen();  // Exit full screen
      } else {
        this.scale.startFullscreen(); // Go full screen
      }
    });
  }

  create() {
    this.background1 = this.add.sprite(910 / 2, 512 / 2, 'back')
    this.background2 = this.add.sprite(this.background1.width, 512 / 2, 'back2')

    this.player = this.physics.add.sprite(50, 300, 'bunny');
    this.player.setScale(2).refreshBody();
    this.player.body.setSize(20, 30);
    this.player.body.setOffset(30, 18);
    this.player.setCollideWorldBounds(true);
    this.player.setBounce(0);

    this.docs = this.physics.add.group();
    this.soldiers = this.physics.add.group();
    this.heart = this.add.group();

    this.anims.create({
      key: 'walk-soldier',
      frames: this.anims.generateFrameNumbers('soldier', { start: 0, end: 9 }),
      frameRate: 10,
      repeat: -1
    });

    this.anims.create({
      key: 'left',
      frames: this.anims.generateFrameNumbers('bunny', { start: 1, end: 8 }),
      frameRate: 10,
      repeat: -1
    });

    this.anims.create({
      key: 'turn',
      frames: [{ key: 'bunny', frame: 0 }],
      frameRate: 20,
    });

    this.anims.create({
      key: 'right',
      frames: this.anims.generateFrameNumbers('bunny', { start: 1, end: 8 }),
      frameRate: 10,
      repeat: -1,
    });

    this.anims.create({
      key: 'jump',
      frames: this.anims.generateFrameNumbers('bunny', { start: 9, end: 14 }),
      frameRate: 4
    });

    this.player.body.setGravityY(300);

    const platforms = this.physics.add.staticGroup();
    const platform = platforms.create(910 / 2, 522, 'floor')
    platform.setScale(1);
    platform.z = 10;
    platform.zIndex = 10;
    platform.refreshBody();


    this.physics.add.collider(this.player, platforms);
    this.physics.add.collider(this.docs, platforms);
    this.physics.add.collider(this.soldiers, platforms);

    for (let i = 0; i < this.lives; i++) {
      const heart = this.heart.create(i * 32, 0, 'heart')
      heart.setScale(1)
      heart.setOrigin(0, 0)
      heart.setAngle(0)
    }

    this.keyboard = this.input.keyboard?.createCursorKeys();
    this.physics.add.overlap(this.player, this.docs, this.getActa, undefined, this);
    this.physics.add.overlap(this.player, this.soldiers, this.soldierAttack, undefined, this);
    this.scoreText = this.add.text(16, 450, 'Actas: 0/30026 | 0%', { fontSize: '24px', color: '#FFF' });
    this.score = 0
    this.gameOver = false
    this.addFullScreenButton()
  }

  mobility() {
    if ((this.keyboard?.up.isDown || this.keyboard?.space.isDown) && this.player.body.touching.down) {
      this.player.setVelocityY(-350);
    }
    if (!this.player.body.touching.down) {
      this.player.anims.play('jump', true);
    }
    if (this.keyboard?.left.isDown) {
      this.player.setVelocityX(-150);

      if (this.player.body.touching.down) {
        this.player.anims.play('left', true);
      }
      this.player.setFlipX(true);
    }
    else if (this.keyboard?.right.isDown) {
      if (this.player.x <= 909 / 2) {
        this.player.setVelocityX(150);
      } else {
        this.player.setVelocityX(0);
      }
      if (this.player.body.touching.down) {
        this.player.anims.play('right', true);
      }
      this.player.setFlipX(false);
    }
    else {
      this.player.setVelocityX(0);

      if (this.player.body.touching.down) {
        this.player.anims.play('turn');
      }
    }
  }

  moveBackground() {
    const scrollSpeed = 1.5;
    // Move backgrounds to the left to simulate player moving right
    if (this.keyboard?.right.isDown && !this.keyboard?.left.isDown) {
      this.background1.x -= scrollSpeed;
      this.background2.x -= scrollSpeed;
    }
    if (this.player.x >= 910 / 2) {
      this.player.x = 910 / 2
    }

    //this.player.x = 910 / 2; // Keep player at the middle of the screen

    // Check if the first background is out of view, then reset its position
    if (this.background1.x <= -(this.background1.width / 2)) {
      this.background1.x = this.background2.x + this.background2.width;
    }

    // Check if the second background is out of view, then reset its position
    if (this.background2.x <= -(this.background2.width / 2)) {
      this.background2.x = this.background1.x + this.background1.width;
    }
  }

  actaSpawn() {
    const lastDoc = this.docs?.getLast(true);
    const scrollSpeed = 1.5;
    if (lastDoc) {
      if (this.keyboard?.right.isDown && !this.keyboard?.left.isDown) {
        lastDoc.x -= scrollSpeed
        if (Phaser.Math.FloatBetween(-600, -200) > lastDoc.x || lastDoc.y > 600) {
          this.docs?.remove(lastDoc, true, true);
          const doc = this.docs?.create(890, 300, 'doc')
          doc.setScale(0.1).refreshBody();
          doc.body.setGravityY(300);
          doc.setBounceY(Phaser.Math.FloatBetween(0.4, 0.8));
        }
      }
    } else {
      if (this.keyboard?.right.isDown && this.player.x >= 910 / 2) {
        const doc = this.docs?.create(890, 300, 'doc')
        doc.setScale(0.1).refreshBody();
        doc.setGravityY(300);
        doc.setBounceY(Phaser.Math.FloatBetween(0.1, 0.5));
      }
    }
  }

  soldiersSpawn() {
    const lastSoldier = this.soldiers?.getLast(true);
    let scrollSpeed = 1.5;
    const frameHeight = 350;
    if (this.keyboard?.right.isDown && !this.keyboard?.left.isDown) {
      scrollSpeed = 3;
    }
    if (lastSoldier) {
      lastSoldier.x -= scrollSpeed
      if (Phaser.Math.FloatBetween(-600, -200) > lastSoldier.x || lastSoldier.y > 600) {
        this.docs?.remove(lastSoldier, true, true);
        const soldier = this.soldiers?.create(850, frameHeight, 'soldier')
        soldier.setScale(0.4).refreshBody();
        soldier.body.setSize(180, 200);
        soldier.body.setOffset(67, 50);
        soldier.anims.play('walk-soldier', true);
        soldier.zIndex = 1
        soldier.z = 0
        soldier.body.setGravityY(300);
      }
    } else {
      const soldier = this.soldiers?.create(850, frameHeight, 'soldier')
      soldier.setScale(0.4).refreshBody();

      soldier.body.setSize(180, 200);
      soldier.body.setOffset(67, 50);
      soldier.anims.play('walk-soldier', true);
      soldier.setGravityY(300);
      soldier.zIndex = 1
    }
  }

  getActa(player: any, doc: any) {
    doc.destroy();

    this.score += Math.floor(Math.random() * (999 - 500 + 1)) + 100;
    this.scoreText?.setText('Actas: ' + this.score + '/30026 | ' + ((this.score / 30026) * 100).toFixed(2) + '%');
  }

  resize() {
    // Resize the game dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;
    // Resize the game canvas to match the window dimensions
    this.scale.resize(width, height);
    // You can also reposition or rescale other game objects here as needed
  }

  soldierAttack(player: any, soldier: any) {
    if (player.x < soldier.x && !this.colliderPlayer) {

      this.lives -= 1
      const heart = this.heart?.getLast(true);
      this.heart?.remove(heart, true, true);
      this.colliderPlayer = true
      if (!this.lives) {
        this.gameOver = true
      }
    }
    if (player.x > soldier.x) {
      this.colliderPlayer = false
    }
    if (this.lives <= 0) { }

  }

  gameOverAction() {
    this.player.setVelocityX(0);
    this.player.anims.play('turn');
    this.soldiers?.getLast(true)?.anims.play('walk-soldier', false);
  }

  update() {
    if (!this.gameOver) {
      this.mobility()
      this.moveBackground()
      this.actaSpawn()
      this.soldiersSpawn()
    }
    if (this.gameOver) {
      this.gameOverAction()
    }
  }
}

export default GameScene