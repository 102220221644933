import { FC, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss"
import { Link } from "react-router-dom";
import { /* Box, */ Drawer/* , IconButton, InputAdornment, TextField */ } from "@mui/material";
/* import { Search } from "@mui/icons-material"; */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/free-solid-svg-icons";
const Header: FC = () => {

  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const date = useMemo(() => {
    return new Date()
  }, [])

  useEffect(() => {
    const windowResize = (event: any) => {
      if (event.target.innerWidth >= 1024) {
        setMenuOpen(false)
      }
    }

    window.addEventListener('resize', windowResize)

    return () => {
      window.removeEventListener('resize', windowResize)
    }
  }, [])

  return (
    <>
      <header className={styles._header}>
        <div className={styles._upperContent}>
          <div className={styles._upperMenu} onClick={toggleMenu}>
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447715 2 0 1.55228 0 1ZM0 7C0 6.44772 0.447715 6 1 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H1C0.447715 8 0 7.55228 0 7ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H17C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12H1Z" fill="white" />
            </svg>
          </div>
          <div className={styles._upperLeft}>
            <div className={styles._leftSide}>
              <div className={styles._logo}>
                <Link to="/" className={styles._link}>
                  <img src="/logo.svg" alt="logo" />
                </Link>
              </div>
              <p className={styles._sloganHeader}>VideoJuegos, Noticias, Información e Investigación</p>
            </div>
            <div className={styles._rightSide}>
              <p className={styles._dateContainer}>
                <span className={styles._date}>{date.toLocaleDateString('es-VE', { year: 'numeric', month: '2-digit', day: '2-digit' })}</span>
                <span className={styles._line}></span>
                <span className={styles._textDate}>Actualizado: </span>
                <span className={styles._time}>{date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', })}</span>
              </p>
            </div>
          </div>
          <div className={styles._upperRight}>
            {/*  <TextField
            className={styles._input}
            placeholder="Buscar..."
            id="filled-start-adornment"

            sx={{ m: 0, width: '15.25rem', '& fieldset': { borderColor: '#DADADA' }, '& input': { padding: '0.375rem 0.75rem', fontSize: '0.875rem' }, '& .MuiInputBase-root': { paddingRight: '0rem', borderColor: '#DADADA', color: '#DADADA' } }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton>
                  <Search htmlColor="#DADADA" />
                </IconButton>
              </InputAdornment>,
            }}
            size="small"
          /> */}
            <ul className={styles._socialContainer}>
              <li className={[styles._social, styles._twitter].join(' ')}>
                <Link to="https://www.twitter.com/la_patilla/" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} color="#D9D9D9" fontSize={'1.375rem'} />
                </Link>
              </li>
              <li className={[styles._social, styles._facebook].join(' ')}>
                <Link to="https://www.facebook.com/lapatilla" target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} color="#D9D9D9" fontSize={'1.375rem'} />
                </Link>
              </li>
              <li className={[styles._social, styles._instagram].join(' ')}>
                <Link to="https://instagram.com/la_patilla?ref=badge" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} color="#D9D9D9" fontSize={'1.375rem'} />
                </Link>
              </li>
              <li className={[styles._social, styles._feed].join(' ')}>
                <Link to="https://www.lapatilla.com/?feed=rss2" target="_blank">
                  <FontAwesomeIcon icon={faRss} color="#D9D9D9" fontSize={'1.375rem'} />
                </Link>
              </li>
              <li className={[styles._social, styles._youtube].join(' ')}>
                <Link to="https://www.youtube.com/user/patillavideo" target="_blank">
                  <FontAwesomeIcon icon={faYoutube} color="#D9D9D9" fontSize={'1.375rem'} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <nav className={styles._nav}>
          <ul className={styles._list}>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="http://lapatilla.com/">
                Portada
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/nacionales/">
                Nacionales
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/regionales/">
                Regiones
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/internacionales/">
                Internacionales
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/opinion/">
                Opinión
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/economia/">
                Economía
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/politica/">
                Política
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/deportes/">
                Deportes
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/entretenimiento/">
                Entretenimiento
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/curiosidades/">
                Curiosidades
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/turismo-2/">
                Turismo
              </Link>
            </li>
            <li className={styles._element}>
              <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/usa/">
                USA
              </Link>
            </li>
          </ul>
        </nav>
        {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
        <IconButton onClick={() => dispatch(changeMenu(!menuOpen))}>
          <Menu />
        </IconButton>
      </Box> */}

      </header >
      <Drawer variant="permanent" sx={{
        position: 'fixed',
        height: '100%',
        zIndex: 10000,
        display: 'block',
        top: 64,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: menuOpen ? '200px' : 0,
          transition: 'all 0.3s ease',
          position: { md: 'absolute', sm: 'absolute', xs: 'absolute' },
          padding: '0rem',
          paddingTop: '2rem',
          paddingBottom: '2rem',
          minHeight: '100%',
          height: '100%',
          backgroundColor: '#040E2F',
          color: '#FFFFFF',
          borderRight: '1px solid rgba(255, 255, 255, 0.15)'
        },
        transition: 'all 0.3s ease',
      }}
        open>
        <ul className={styles._list}>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="http://lapatilla.com/">
              Portada
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/nacionales/">
              Nacionales
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/regionales/">
              Regiones
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/internacionales/">
              Internacionales
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/opinion/">
              Opinión
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/economia/">
              Economía
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/politica/">
              Política
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/deportes/">
              Deportes
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/entretenimiento/">
              Entretenimiento
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/curiosidades/">
              Curiosidades
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/turismo-2/">
              Turismo
            </Link>
          </li>
          <li className={styles._element}>
            <Link className={styles._link} target="_blank" to="https://www.lapatilla.com/secciones/usa/">
              USA
            </Link>
          </li>
        </ul>
      </Drawer>
    </>
  )
}

export default Header