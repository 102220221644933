import { Home, Patijam } from "@/pages"
import CategoriesPage from "@/pages/CategoryPage"
import GamePage from "@/pages/GamePage"
import { FC } from "react"
import { Route, Routes } from "react-router-dom"

const MainRouter: FC = () => {

  return (
    <Routes>
      <Route path="/patijam" element={<Patijam />} />
      <Route path="/" element={<Home />} />
      <Route path="/:category" element={<CategoriesPage />} />
      <Route path="/games/:url" element={<GamePage />} />
      <Route path="/cache-healthcheck" element={<p>HealthyApp</p>} />
    </Routes>
  )
}

export default MainRouter