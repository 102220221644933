import { Box } from "@mui/material"
import { FC, useMemo } from "react"
import styles from "./styles.module.scss"
/* import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, FreeMode } from "swiper/modules" */

const SponsorsSlider: FC = () => {

  const slides = useMemo(() => {
    return [
      {
        url: 'https://www.lapatilla.com/',
        image: '/images/lapatilla.png'
      },
      {
        url: 'https://www.theempire.tech/',
        image: '/images/theempire.png'
      }
    ]
  }, [])

  return (
    <Box className={styles._container}>
      <h3 className={styles._title}>Patrocinadores</h3>
      <Box className={styles._logosContainer}>
        {slides?.map((slide: any, index: number) => (
          <a target="_blank" rel="noreferrer" key={index} href={slide?.url}><Box className={styles._imageContainer}>
            <img src={slide?.image} alt="" className={styles._image} />
          </Box></a>
        ))}
      </Box>
      {/* <Swiper modules={[Autoplay, FreeMode]} slidesPerView={'auto'} loop={true} autoplay={{ delay: 5000 }} freeMode={{ enabled: true }} className={styles._swiper}>
        {slides?.map((slide: any, index: number) => (
          <SwiperSlide key={index} className={styles._slide}>
            <Box className={styles._imageContainer}>
              <img src={slide} alt="" className={styles._image} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper> */}
    </Box>
  )
}

export default SponsorsSlider