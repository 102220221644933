import { FC, useEffect, useState } from "react"
import styles from "./styles.module.scss"
import { Box, Button } from "@mui/material"
import { FetchService } from "@/utils"
import Card from "../Card"
const CategoryComponent: FC<any> = ({ category }) => {

  const [games, setGames] = useState<any>([])

  const getGames = async () => {
    try {
      const data = await FetchService('game/paginate', 'POST', { page: 1, limit: 12, query: { category: [category?.name], feature: true, newGame: category?.url === 'news' } })
      setGames(data?.result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (category?.name) getGames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return games?.length > 0 ? (
    <Box className={styles._container}>
      <Box className={styles._headerContainer}>
        <h3 className={styles._title}>{category?.name || ''}</h3>
        <Button className={styles._button} variant="contained">Ver más</Button>
      </Box>
      <Box className={styles._content}>
        {games?.map((item: any, index: number) => (
          <Card key={`${item?.name}-${index}`} game={item} />
        ))}
      </Box>
    </Box>
  ) : null
}

export default CategoryComponent