import { Box } from "@mui/material";
import { FC } from "react";
import styles from "./styles.module.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Arrow } from "./elements";
const Features: FC<any> = ({ title = 'Juegos Destacados', data }) => {
  return data?.length ? (
    <Box sx={{ width: '100%', maxWidth: '881px', margin: '0 auto', padding: '2rem 0rem' }}>
      <h3 className={styles._title}>{title}</h3>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Swiper
          className={styles._swiper}
          slidesPerView={1}
          loop
        >
          <Box className={styles._arrowContainer}>
            <Arrow direction={'prev'} />
          </Box>
          {data?.map((item: any, index: number) => (
            <SwiperSlide className={styles._slide} key={index}>
              {item}
            </SwiperSlide>
          ))}
          <Box className={styles._arrowContainer + ' ' + styles._arrowLeft}>
            <Arrow direction={'next'} />
          </Box>
        </Swiper>
      </Box>
    </Box>
  ) : null
}

export default Features