import Phaser from "phaser";
import background from '../assets/avila.jpg'
import character from '../assets/TestCharacter.png';
class MenuScene extends Phaser.Scene {
  constructor() {
    super('menu');
  }

  preload() {
    this.load.image('background', background);
    this.load.spritesheet('character', character, { frameWidth: 65, frameHeight: 48, margin: -8, spacing: 0 });
  }

  createButton() {
    const button = this.add.rectangle(430, 400, 200, 80, 0x284B63).setInteractive();
    
    // Create a text label for the button
    const textButton = this.add.text(430, 400, 'Empezar', {
      fontSize: '32px',
      color: '#FFFFFF',
    }).setOrigin(0.5);

    // Handle pointer events for interactivity
    button.on('pointerover', () => {
      button.setFillStyle(0x8BB4D0);  // Change color when hovered
      textButton.setColor('#353535');
    });

    button.on('pointerout', () => {
      button.setFillStyle(0x284B63);
      textButton.setColor('#FFFFFF');  // Reset color when not hovered
    });

    button.on('pointerdown', () => {
      this.scene.launch('game');
      this.scene.remove('menu');
      // Trigger any action you want here
    });
  }

  create() {
    this.add.sprite(910 / 2, 512 / 2, 'background')
    const text = this.add.text(910 / 2, 100, 'TestigoMan 2024', { fontSize: '64px', color: '#000', fontStyle: 'bold', shadow: { offsetX: 2, offsetY: 2, color: '#fff', blur: 2, fill: true } });
    text.setOrigin(0.5);
    const character = this.add.sprite(400, 200, 'character')
    character.setScale(3)

    this.createButton()

  }
}

export default MenuScene