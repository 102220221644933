import Example from "./Example";
import McActas from "./McActas";
import city from "./McActas/assets/city.png";

const games = [
  {
    name: 'TestigoMan 2024',
    url: 'testigoman-2024',
    description: `# TestigoMan 2024   
---
### Descripción
 

**TestigoMan 2024** es un videojuego de plataforma ambientado en las elecciones presidenciales de Venezuela, en julio de 2024. En este juego, encarnas a un testigo de mesa anónimo que tiene la misión de demostrar el verdadero resultado de las elecciones. 

Debes recolectar el 70% de las actas de escrutinio distribuidas por distintos centros de votación y evitar ser atrapado por los militares del Plan República, quienes intentarán detenerte. El destino del país está en tus manos, y cada decisión que tomes será crucial para la historia.

---

### Objetivo del juego

Recolectar al menos el 70% de las actas de escrutinio que están en circulación antes de ser capturado. Solo así podrás demostrar los verdaderos resultados de las elecciones.

---

### Mecánicas de juego

- **Moverse:** Utiliza las **flechas de izquierda y derecha** para desplazarte por los diferentes escenarios.
- **Saltar:** Presiona la **flecha de arriba** para saltar obstáculos y evadir a los militares.

---

### Características

- **Ambientación política realista:** Los escenarios están inspirados en el clima electoral y los eventos que ocurren en Venezuela.
- **Finales múltiples:** El desenlace dependerá de la cantidad de actas que recolectes y las decisiones que tomes en el camino.

**¿Serás capaz de revelar la verdad y marcar un nuevo rumbo para el país?**`,
    image: city,
    scene: McActas,
  },
  {
    name: 'Random Manuel',
    url: 'random-manuel',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: 'https://decode.sfo3.cdn.digitaloceanspaces.com/games/randomManuel/index.html',

  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  },
  {
    name: 'Example',
    url: 'example',
    description: 'Example description',
    image: 'https://camo.githubusercontent.com/6f252ebd5fd6bebda018a9a37b7ce9c920450f812f971e4da4e2352cf472c0ff/68747470733a2f2f696d672e796f75747562652e636f6d2f76692f676e6667776b56673776632f6d617872657364656661756c742e6a7067',
    scene: Example,
  }
]

export default games