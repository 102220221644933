import { Games } from "@/games";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss"
import { Box, Tab, Tabs } from "@mui/material";
import { GamesList, Layout } from "@/components";
import { TabPanel } from "./elements";
import { FetchService } from "@/utils";
import Markdown from "react-markdown";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GamePage: FC = () => {
  const { url } = useParams()

  const [config, setConfig] = useState<any>(null)
  const [value, setValue] = useState(0);
  const [game, setGame] = useState<any>(null)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const selectedGame: any = useMemo(() => {
    const gameSelected = Games.find(game => game.url === url)
    return gameSelected
  }, [url])


  useEffect(() => {
    if (selectedGame && typeof selectedGame?.scene !== 'string') {
      setConfig(
        {
          type: Phaser.AUTO,
          parent: 'game-container',
          scene: selectedGame?.scene,
          width: 960,
          height: 540,
          physics: {
            default: 'arcade',
            arcade: {
              gravity: { y: 200, x: 0 },
              debug: true
            }
          },
          scale: {
            mode: Phaser.Scale.EXPAND,  // Resize while maintaining aspect ratio
            autoCenter: Phaser.Scale.CENTER_BOTH
          }
        }
      )
    }
    // eslint-disable-next-line
  }, [selectedGame])

  useEffect(() => {
    let game: any;
    if (config) {
      game = new Phaser.Game(config);
    }
    return () => {
      game?.destroy(true)
    }
    // eslint-disable-next-line
  }, [config])


  const getGame = async () => {
    try {
      const data = await FetchService(`game/${url}`)
      setGame(data?.result)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getGame()
    // eslint-disable-next-line
  }, [url])

  return (
    <Layout>
      <Box className={styles._container}>
        <h1 className={styles._title}>{game?.name}</h1>
        {game?.url === url && < div id="game-container" className={styles._gameContainer} >
          {typeof game?.scene === 'string' && (
            <iframe allowFullScreen id="game-iframe" className={styles._iframe} title={game?.name} src={game?.scene}></iframe>
          )}
        </div>}
        {/* <Box className={styles._gameInfo}>
          <Box className={styles._likes}>
            <img src="/images/like.svg" alt="like" width={24} height={24} />
            <p className={styles._likesText}>{game?.likes || 0}</p>
          </Box>
        </Box> */}
      </Box>{/* 
      <Box className={styles._adContainer}>
        <Box sx={{ border: '1px solid #DADADA', color: '#DADADA', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '160px' }}>
          AD
        </Box>
      </Box> */}
      <Box className={styles._gameData}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: '#FFFFFF99', width: '100%' }}>
            <Tabs sx={{ width: '100%', '& .MuiTabs-indicator': { backgroundColor: '#50AC3F' }, '& .css-1v8iiz5-MuiButtonBase-root-MuiTab-root.Mui-selected': { color: '#ED8989' } }} variant="fullWidth" value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab className={styles._tab} sx={{ color: '#FFFFFF99', borderColor: '#FFFFFF99' }} label="Acerca de" {...a11yProps(0)} />
              {game?.objective && <Tab className={styles._tab} sx={{ color: "#FFFFFF99", borderColor: '#FFFFFF99' }} label="Objetivo del juego" {...a11yProps(1)} />}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box className={styles._infoBox}>
              <h4 className={styles._infoTitle}>Descripción</h4>
              <Markdown className={styles._infoText}>{game?.description}</Markdown>
            </Box>
            {game?.developer?.length > 0 && <Box className={styles._infoBox}>
              <h4 className={styles._infoTitle}>Desarrollador</h4>
              <Box className={styles._infoTextContainer}>
                {game?.developer?.map((dev: any, index: number) => (
                  <p className={styles._infoText} key={index}>{dev?.name}</p>
                ))}
              </Box>
            </Box>}
            {game?.categories?.length > 0 && <Box className={styles._infoBox}>
              <h4 className={styles._infoTitle}>Categorias</h4>
              <Box className={styles._infoTextContainer}>
                {game?.categories?.map((dev: any, index: number) => (
                  <p className={styles._infoText} key={index}>{dev?.name}</p>
                ))}
              </Box>
            </Box>}
            <Box className={styles._infoBox}>
              <h4 className={styles._infoTitle}>Publicado en</h4>
              <p className={styles._infoText}>{new Date(game?.created_at).toLocaleDateString('es-VE')}</p>
            </Box>
            {!!game?.updated_at && <Box className={styles._infoBox}>
              <h4 className={styles._infoTitle}>Última actualización</h4>
              <p className={styles._infoText}>{new Date(game?.updated_at).toLocaleDateString('es-VE')}</p>
            </Box>}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box className={styles._infoBox}>
              <h4 className={styles._infoTitle}>Objetivo del juego</h4>
              <Markdown className={styles._infoText}>{game?.objective}</Markdown>
            </Box>
          </TabPanel>
        </Box>
      </Box>
      <Box>
        <h3 className={styles._title2}>Juegos parecidos</h3>
        <Box className={styles._similarGamesContainer}>
          <GamesList category={game?.categories?.map((item: any) => item?.name)} />
        </Box>
      </Box>
    </Layout >
  )
}

export default GamePage