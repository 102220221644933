import { Box, Button, Divider, Drawer, IconButton } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { NewReleasesOutlined, ShieldOutlined } from "@mui/icons-material"
import { FetchService } from "@/utils";
import { Link } from "react-router-dom";
import GamesList from "../GamesList";
import styles from './styles.module.scss'

const Layout: FC<any> = ({ children }) => {

  const [categories, setCategories] = useState<any>([])

  const fetchCategories = async () => {
    try {
      const data = await FetchService('category')
      setCategories(data?.result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const categoriesSelected = useMemo(() => {
    return {
      featured: categories?.filter((category: any) => category.feature),
      others: categories?.filter((category: any) => !category.feature)
    }
    // eslint-disable-next-line
  }, [categories?.length])

  const [subMenu, setSubMenu] = useState<any>(true)

  const toggleSubMenu = () => {
    setSubMenu(!subMenu)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200 && !subMenu) {
        setSubMenu(true)
      }
      if (window.innerWidth < 1200 && subMenu) {
        setSubMenu(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [subMenu])

  useEffect(() => {
    if (window.innerWidth <= 1200 && subMenu) {
      setSubMenu(false)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <Box className={styles._container} sx={{ display: 'grid', width: '100%', height: '100%', background: '#040E2F' }}>
      <Box
        component="nav"
        sx={{
          width: { xs: 0, sm: 0, md: 0, lg: 156 },
          position: 'relative',
          zIndex: 1000,
          transition: 'all 0.3s ease',
          display: 'block',
          height: 'initial',
          minHeight: '100%',
        }}>
        <Drawer variant="permanent" sx={{
          height: '100%',
          display: 'block',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { lg: 156, md: subMenu ? 156 : 45, sm: subMenu ? '156px' : 45, xs: subMenu ? '100vw' : 45 },
            transition: 'all 0.3s ease',
            position: { md: 'absolute', sm: 'absolute', xs: 'absolute' },
            padding: '0rem',
            paddingTop: '2rem',
            paddingBottom: '2rem',
            minHeight: '100%',
            height: '100%',
            backgroundColor: '#040E2F',
            color: '#FFFFFF',
            borderRight: '1px solid rgba(255, 255, 255, 0.15)'
          },
          transition: 'all 0.3s ease',
        }}
          open>
          <Box className={styles._logoContainer}>
            <IconButton sx={{ paddingLeft: '3px' }} className={[styles._toggleButton].join(' ')} onClick={toggleSubMenu}>
              <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H13.3333V2.66667H0V0ZM0 13.3333H13.3333V16H0V13.3333ZM0 6.66667H16V9.33333H0V6.66667ZM17.3333 1.33333L15.44 3.18667L20.1867 8L15.44 12.8133L17.3333 14.6667L24 8L17.3333 1.33333Z" fill="#9195A3" />
              </svg>
            </IconButton>
          </Box>
          {!!categoriesSelected?.featured?.length &&
            categoriesSelected?.featured?.map((category: any) => (
              <Link key={category?.url} to={`/${category?.url}`}>
                <Button variant="text" sx={{ width: '100%', padding: '0.5rem 1rem', textTransform: 'none', justifyContent: 'flex-start' }} size="large" color="inherit" startIcon={<NewReleasesOutlined />}>
                  {subMenu && category?.name}
                </Button>
              </Link>
            ))}
          <Divider variant="middle" sx={{ color: 'rgba(255, 255, 255, 0.15)', borderColor: 'rgba(255, 255, 255, 0.15)', margin: 0 }} />
          {!!categoriesSelected?.others?.length &&
            categoriesSelected?.others?.map((category: any) => category?._count?.games > 0 ? (
              <Link to={`/${category?.url}`} key={category?.url}>
                <Button variant="text" sx={{ width: '100%', padding: '0.5rem 1rem', textTransform: 'none', justifyContent: 'flex-start' }} size="large" color="inherit" startIcon={<ShieldOutlined />}>
                  {subMenu && category?.name}
                </Button>
              </Link>
            ) : null)
          }
        </Drawer>
      </Box>
      <Box sx={{ flexGrow: 1, width: `100%` }}>
        {children}
      </Box>
      {/*   <Box className={styles._floatingAd}>
        <Box sx={{ border: '1px solid #DADADA', color: '#DADADA', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '178px' }}>
          AD
        </Box>
      </Box> */}
      <Box className={styles._adBar} sx={{ paddingTop: '2rem', paddingBottom: '2rem', paddingRight: '1rem' }} >
        <Box>
          {/*  <Box marginTop="5rem" sx={{ border: '1px solid #DADADA', color: '#DADADA', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '178px' }}>
            AD
          </Box> */}
          <GamesList fullWidth={true} />
          {/* <Box sx={{ border: '1px solid #DADADA', color: '#DADADA', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '400px' }}>
            AD
          </Box> */}
        </Box>
      </Box>
    </Box>
  )
}

export default Layout