import { Box } from "@mui/material";
import { FC } from "react";

const CustomTabPanel: FC<any> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: '1.5rem', display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>{children}</Box>}
    </div>
  );
}

export default CustomTabPanel